<template>
<!-- 轮播图 -->
    <Banner :imgArr="state.bannerArr" v-if="state.bannerArr!=''" />
    <div class="section">
        <h2>{{state.newsTitle}}</h2>
        <div class="timeBox"><span>{{formatDate1(state.time)}}</span><span>浏览：{{state.views}}</span></div>
        <div class="info" v-html="state.info"></div>
    </div>
</template>
<style  lang="scss" scoped>
.section{
    width: 10rem;
    margin: 0 auto;
    padding: .2rem 0;
    h2{
        font-size: .2rem;
        font-weight: normal;
        text-align: center;
    }
    .timeBox{
        font-size: .13rem;
        text-align: center;
        color: #999;
        display: flex;
        justify-content:center;
        span{
            display: block;
            margin: .1rem .1rem;
        }
    }
    .info{
        font-size: .14rem;
        line-height: .26rem;
        margin: .1rem 0;
        img{
            margin: .1rem auto;
        }
        p{
            margin: .2rem 0;
            text-indent: .2rem;
            text-align: left;
        }
    }
}
</style>
<script>
import {onMounted,reactive} from "vue";
import { useRoute } from 'vue-router'
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
import { getNewDetail,getbannerData} from "../../api/request"
import { formatDate } from '@/assets/utils/utils';
export default {
    components:{
        Banner
    },
    setup(){
        const route = useRoute()
        onMounted(()=>{
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
            state.newsId = route.query.id
            getData(state.newsId)
        })
        let state = reactive({
            bannerArr:[],
            newsTitle:"",
            newsId:0,
            info:"",
            views:0,
            time:""
        })
        
        function getData(id){
            getbannerData({id:40}).then((res)=>{
            if(res.code == 200){
              state.bannerArr = res.data
            }
          })
            getNewDetail({id:id}).then((res) => {
              console.log(res)
              if(res.code == 200){
                state.newsTitle = res.data.title
                state.info = res.data.content
                state.time = res.data.create_time
                state.views = res.data.views
              }
          })
        }
        //时间

       function formatDate1(time) {
            time = time * 1000
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd')
        }
        return{
            state,
            getData,
            formatDate1
        }
    }
}
</script>