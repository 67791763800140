<template>
    <el-carousel  :height="state.bannerHeight+'px'" style="margin-top:80px">
      <el-carousel-item
        v-for="(item, index) in imgArr"
        :key="index"
        :class="'index' + index"
      >
            <img :src="item.images" />
      </el-carousel-item>
    </el-carousel>
</template>
<script>
import {onMounted, reactive} from "vue";
export default {
    props:{
        imgArr:{
            type:Array
        }
    },
    setup(){
      let state = reactive({
        screenWidth:0,
        bannerHeight:400
      })
      onMounted(()=>{
        state.screenWidth = window.innerWidth
        setSize()
        window.onresize=()=>{
          state.screenWidth = window.innerWidth
        }
      })
      function setSize(){
        state.bannerHeight = 400/1920*state.screenWidth
      }
      return{
        state,
        setSize
      }
    }
};
</script>
<style lang='scss' scoped>
img{
  width: 100%;
  height: 100%;
}
</style>